import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import { graphql } from 'gatsby'
import styled from 'styled-components'
// import '../fragments'

const HomeBackground = styled.div`
  left: 0;
  top: 0;
  & img {
    object-position: left center !important;
  }

  & .gatsby-image-wrapper {
    height: 100vh;
  }
`

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const background = data.backgroundImage.image

    return (
      <HomeBackground>
        <Image
          sizes={{
            ...background.fluid,
            base64: background.sqip.dataURI,
          }}
        />
      </HomeBackground>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query {
    backgroundImage: contentfulHeroImage(slug: { eq: "workstation" }) {
      image {
        ...BigSqipPreview
      }
    }
  }
`
